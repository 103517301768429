import { fractalMixin } from '@/mixins/fractalMixin'

export const AccountingMixin = {
  data() {
    return {
      resourceType: 'servicePlannings',
      filterValue: '',
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Planta',
            dataIndex: '',
            key: 'workCenters',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'workCenters',
            },
          },
          {
            title: 'Servicio',
            dataIndex: 'sap_programming_code',
            key: 'sap_programming_code',
            class: 'text-center small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
            },
            onFilter: (value, record) => record.sap_programming_code.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Cliente',
            dataIndex: '',
            key: 'service_planeable',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'service_planeable',
            },
          },
          {
            title: 'Información de Servicio',
            dataIndex: '',
            key: 'service_detail',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'service_detail',
            },
          },
          {
            title: '% de Cierre de Manifiestos',
            dataIndex: 'manifestPercentage.text',
            key: 'manifestPercentage',
            class: 'text-center small strong',
          },
          {
            title: 'Acción',
            dataIndex: '',
            class: 'text-center small',
            key: 'x',
            scopedSlots: {
              customRender: 'action',
            },
          },
        ],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        perPageValue: 15,
        currentPageValue: 1,
      },
      tableObjectSapResponse: {
        data: [],
        columns: [
          {
            title: 'Planta',
            dataIndex: '',
            key: 'workCenters',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'workCenters',
            },
          },
          {
            title: 'Servicio',
            dataIndex: 'sap_programming_code',
            key: 'sap_programming_code',
            class: 'text-center small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
            },
            onFilter: (value, record) => record.sap_programming_code.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Cliente',
            dataIndex: '',
            key: 'service_planeable',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'service_planeable',
            },
          },
          {
            title: 'Información de Servicio',
            dataIndex: '',
            key: 'service_detail',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'service_detail',
            },
          },
          {
            title: 'Respuestas de SAP',
            dataIndex: '',
            key: 'sap_response',
            scopedSlots: {
              customRender: 'sap_response',
            },
            class: 'text-center small strong',
          },
          {
            title: 'Facturación',
            dataIndex: '',
            key: 'billing',
            scopedSlots: {
              customRender: 'billing',
            },
            class: 'text-center small strong',
          },
          {
            title: 'Acción',
            dataIndex: '',
            class: 'text-center small',
            key: 'x',
            scopedSlots: {
              customRender: 'action',
            },
          },
        ],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        perPageValue: 15,
        currentPageValue: 1,
      },
      tableObjectMaterials: {},
      // ******************************
      // Filters
      filterService: '',
      filterWorkCenter: '',
      filterContract: undefined,
      contractCombo: [],
      filterClient: undefined,
      clientCombo: [],
      // Filters
      // ******************************
      // ******************************
      // Modal Form
      billingForm: {
        id: '0',
        billing_code: '',
        billing_status_name: 'No Facturado',
        is_billing: false,
        billing_dated_at: undefined,
        load: false,
      },
      billingStatusOptions: ['No Facturado', 'Facturado'],
      billingModalRules: {
        billing_code: [
          { required: true, message: 'El campo Número de factura es obligatorio.', trigger: 'blur' },
        ],
        billing_status_name: [
          { required: true, message: 'El campo Estatus de facturación es obligatorio.', trigger: 'blur' },
        ],
        billing_dated_at: [
          { required: true, message: 'El campo Fecha de facturación es obligatorio.', trigger: 'blur' },
        ],
      },
      // Modal Form
      // ******************************
    }
  },
  mixins: [fractalMixin],
  methods: {
    async GetMainTable(pagEvent = {}) {
      const params = {
        'page[number]': (Object.keys(pagEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.tableObject.currentPage}`,
        'page[size]': `${this.tableObject.pagination.pageSize}`,
        sort: this.tableObject.sortValue,
        include: 'users,shifts,workCenters,service_planeable,serviceTypes,manifests',
        'filter[servicePlanningOpenManifest]': this.filterValue,
      }
      if (this.filterWorkCenter) params['filter[workCenters]'] = this.filterWorkCenter
      if (this.filterService) params['filter[keys]'] = this.filterService
      if (this.filterClient) params['filter[clients]'] = this.filterClient
      if (this.filterContract) params['filter[clientContracts]'] = this.filterContract
      await this.GetTableResource(pagEvent, this.resourceType, this.tableObject, params)
    },
    async MixGetSapResponseTable(pagEvent = {}) {
      const params = {
        'page[number]': (Object.keys(pagEvent).length !== 0) ? `${this.tableObjectSapResponse.pagination.current}` : `${this.tableObjectSapResponse.currentPage}`,
        'page[size]': `${this.tableObjectSapResponse.pagination.pageSize}`,
        sort: this.tableObjectSapResponse.sortValue,
        include: 'users,shifts,workCenters,service_planeable,serviceTypes,manifests,sapResponses',
        'filter[servicePlanningCloseManifest]': this.filterValue,
      }
      if (this.filterWorkCenter) params['filter[workCenters]'] = this.filterWorkCenter
      if (this.filterService) params['filter[keys]'] = this.filterService
      if (this.filterClient) params['filter[clients]'] = this.filterClient
      if (this.filterContract) params['filter[clientContracts]'] = this.filterContract
      await this.GetTableResource(pagEvent, this.resourceType, this.tableObjectSapResponse, params)
    },
    changeStatusToFlag(billingStatus) {
      return (billingStatus === 'Facturado')
    },
    changeFlagToStatus(flag) {
      return (flag === true) ? 'Facturado' : 'No Facturado'
    },
  },
}
