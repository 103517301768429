<template>
  <div>
    <a-table
      :scroll="{x:1300}"
      bordered
      :dataSource="value.data"
      :columns="value.columns"
      :loading="value.loading"
      :row-key="record => record.sap_programming_code"
      :pagination="value.pagination"
      @change="RefreshResults"
    >
      <template slot="expandedRowRender" slot-scope="record" style="margin: 0">
        <a-row v-for="manifest in record.manifests" :key="manifest.number">
          <a-row>
            <a-col span="6" class="text-center small text-primary">
              <b-icon icon="file-text-fill"></b-icon>
              <strong>Manifiesto: </strong> <strong>{{ manifest.number }}</strong>
            </a-col>
            <a-col span="6" class="text-center small">
              <strong>Autorización de Laboratorio: </strong>
              <strong class="text-primary" v-show="manifest.lab_checks">
                <b-icon icon="check-circle-fill"></b-icon>
                Si requiere</strong>
              <strong class="text-danger" v-show="!manifest.lab_checks">
                <b-icon icon="x-circle-fill"></b-icon>
                No requiere
              </strong>
            </a-col>
            <a-col span="6" class="text-center small">
              <strong>Estatus de Descarga: </strong>
              <strong class="text-primary" v-show="manifest.is_discharge_close">
                <b-icon icon="check-circle-fill"></b-icon>
                Cerrado
              </strong>
              <strong class="text-danger" v-show="!manifest.is_discharge_close">
                <b-icon icon="x-circle-fill"></b-icon>
                No Cerrado
              </strong>
            </a-col>
            <a-col span="6" class="text-center small">
              <strong>Inicio de Descarga: </strong> {{ manifest.initial_dated_at }}
            </a-col>
          </a-row>
          <a-row class="pt-2">
            <a-col span="12" class="text-center small">
              <strong> {{ manifest.recepcionist.type }}: </strong> {{ manifest.recepcionist.users.name }}
            </a-col>
            <a-col span="12" class="text-center small">
              <strong> {{ manifest.supervisor.type }}: </strong> {{ manifest.supervisor.users.name }}
            </a-col>
          </a-row>
          <hr class="bg-primary" v-show="record.manifests.length > 0"/>
        </a-row>
      </template>

      <div slot="filterDropdown"
           slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
           class='custom-filter-dropdown'>
        <a-input
          v-ant-ref="c => searchInput = c"
          :placeholder="`Buscar por ${column.title}`"
          :value="selectedKeys[0]"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() =>{  return new HandleSearch(selectedKeys, confirm) }"
          style="width: 188px; margin-bottom: 8px; display: block;"
        />
        <a-button
          type='primary'
          @click="() =>{  return new HandleSearch(selectedKeys, confirm) }"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >Buscar
        </a-button>
        <a-button
          @click="() =>{  return new HandleReset(clearFilters) }"
          size="small"
          style="width: 90px"
        >Limpiar
        </a-button>
      </div>
      <a-icon slot="filterIcon" type='search' slot-scope="filtered"
              :style="{ color: filtered ? '#108ee9' : undefined }"/>
      <template slot="customRender" slot-scope="text">
        <span v-if="searchText">
          <template
            v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i"
                  class="highlight">{{ fragment }}</mark>
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>{{ text }}</template>
      </template>
      <template slot="workCenters" slot-scope="text, record">
        {{ record.workCenters.code }} - {{ record.workCenters.name }}
      </template>
      <template slot="service_planeable" slot-scope="text, record">
        <template v-if="record.serviceTypes.name === 'Entrada por Traspaso'">
          <small>
            <strong>
              Tipo:
            </strong>
          </small>
          <small>{{ record.serviceTypes.expedition_class }} - {{ record.serviceTypes.name }}</small>
          <hr class="bg-primary">
          <small><strong class="text-center">Cliente Interno: </strong></small>
          <small>{{ record.service_planeable.sap_code }} - {{ record.service_planeable.name }}</small>
        </template>
        <template v-else-if="record.serviceTypes.name === 'Salida por Disposición'">
          <small><strong>Tipo: </strong></small>
          <small>{{ record.serviceTypes.expedition_class }} - {{ record.serviceTypes.name }}</small>
          <hr class="bg-primary">
          <small><strong class="text-center">Destino: </strong></small>
          <small>{{ record.service_planeable.code }} - {{ record.service_planeable.name }}</small>
        </template>
        <template v-else-if="record.serviceTypes.name === 'Entrada por Compra'">
          <small>
            <strong class="text-center">
              Contrato:
            </strong>
          </small>
          <small>{{ record.service_planeable.contract_code }}</small>
          <hr class="bg-primary">
          <small>
            <strong class="text-center">
              Cliente (sap-clave-nombre):
            </strong>
          </small>
          <small>
            {{ record.service_planeable.meta.clientInfo }}
          </small>
        </template>
        <template v-else>
          <small><strong>Tipo: </strong></small>
          <small>{{ record.serviceTypes.name }}</small>
          <hr class="bg-primary">
          <small><strong class="text-center">Valor: </strong></small>
          <small>{{ record.service_planeable.name }}</small>
        </template>
      </template>
      <template slot="service_detail" slot-scope="text, record">
        <small>
          <strong>
            Ing. de Servicio:
          </strong>
        </small>
        <small>{{ record.users.name }}</small>
        <hr class="bg-primary">
        <small>
          <strong>
            Tipo de Servicio:
          </strong>
        </small>
        <small>{{ record.serviceTypes.code }} - {{ record.serviceTypes.name }}</small>
        <hr class="bg-primary">
        <small>
          <strong>
            Turno:
          </strong>
        </small>
        <small>{{ record.shifts.code }} - {{ record.shifts.name }}</small>
        <hr class="bg-primary">
        <small>
          <strong>
            Etapa en curso:
          </strong>
        </small>
        <small>{{ record.lastSubstage }}</small>
      </template>
      <template slot="myCustomColumns" slot-scope="text, record">
        <slot name="details" :record="record"/>
      </template>
      <template slot="recepcionist" slot-scope="text, record">
        <strong class="text-center">{{ record.recepcionist.users.name }}</strong>
      </template>
      <template slot="supervisor" slot-scope="text, record">
        <strong class="text-center">{{ record.supervisor.users.name }}</strong>
      </template>
      <template slot="dated_at" slot-scope="text, record">
        <div class="text-center">
          {{
            moment(text).format('YYYY-MM-DD HH:mm')
          }}
          <div style="display: none">
            {{ record }}
          </div>
        </div>
      </template>
      <template slot="lab" slot-scope="text, record">
        <strong class="text-primary" v-show="record.lab_checks">
          <b-icon icon="check-circle-fill"></b-icon>
          Si requiere</strong>
        <strong class="text-danger" v-show="!record.lab_checks">
          <b-icon icon="x-circle-fill"></b-icon>
          No requiere</strong>
      </template>
      <template slot="collectors" slot-scope="record">
        <div v-if="record.collectors != null">
          <span v-if="Object.keys(record.collectors).length > 0">
            {{ record.collectors.name }}
          </span>
          <span v-else>NA</span>
        </div>
        <span v-else>NA</span>
      </template>
      <template slot="is_discharge_closes" slot-scope="record">
        <strong class="text-primary" v-show="record">
          <b-icon icon="check-circle-fill"></b-icon>
          Cerrado
        </strong>
        <strong class="text-danger" v-show="!record">
          <b-icon icon="x-circle-fill"></b-icon>
          No Cerrado
        </strong>
      </template>
      <template slot="sap_response" slot-scope="record">
        <div v-if="record.sapResponses">
          <div>
            <span><strong>Transacción SAP: </strong></span>
            <span>VA01 Programación del Pedido</span>
            <hr>
            <span><strong>Código SAP VA01: </strong></span>
            <span>{{ record.sapResponses.va01_code }}</span>
            <hr>
            <span><strong>Fecha: </strong></span>
            <span>{{ record.sapResponses.va01_dated_at }}</span>
            <template v-if="record.sapResponses.va01_is_error">
              <strong class="text-danger">
                <b-icon icon="x-circle-fill"></b-icon>
                <hr>
                {{ record.sapResponses.va01_sap_response }}
              </strong>
            </template>
          </div>
          <hr class="bg-primary height-10">
          <div>
            <span><strong>Transacción SAP: </strong></span>
            <span>VL01N Generación de Entrega</span>
            <hr>
            <span><strong>Código SAP VL01N: </strong></span>
            <span>{{ record.sapResponses.vl01n_code }}</span>
            <hr>
            <span><strong>Fecha: </strong></span>
            <span>{{ record.sapResponses.vl01n_dated_at }}</span>
            <template v-if="record.sapResponses.vl01n_is_error">
              <strong class="text-danger">
                <b-icon icon="x-circle-fill"></b-icon>
                <hr>
                {{ record.sapResponses.vl01n_sap_response }}
              </strong>
            </template>
          </div>
          <hr class="bg-primary height-10">
          <div>
            <span><strong>Transacción SAP: </strong></span>
            <span>VT01N Creación del Transporte</span>
            <hr>
            <span><strong>Código SAP VT01N: </strong></span>
            <span>{{ record.sapResponses.vt01n_code }}</span>
            <hr>
            <span><strong>Fecha: </strong></span>
            <span>{{ record.sapResponses.vt01n_dated_at }}</span>
            <template v-if="record.sapResponses.vt01n_is_error">
              <strong class="text-danger">
                <b-icon icon="x-circle-fill"></b-icon>
                <hr>
                {{ record.sapResponses.vt01n_sap_response }}
              </strong>
            </template>
          </div>
          <hr class="bg-primary height-10">
          <div>
            <span><strong>Transacción SAP: </strong></span>
            <span>VT02N Programación del Pedido</span>
            <hr>
            <span><strong>Código SAP VT02N: </strong></span>
            <span>{{ record.sapResponses.vt02n_code }}</span>
            <hr>
            <span><strong>Fecha: </strong></span>
            <span>{{ record.sapResponses.vt02n_dated_at }}</span>
            <template v-if="record.sapResponses.vt02n_is_error">
              <strong class="text-danger">
                <b-icon icon="x-circle-fill"></b-icon>
                <hr>
                {{ record.sapResponses.vt02n_sap_response }}
              </strong>
            </template>
          </div>
        </div>
        <div v-else>
          <strong>Sin información de SAP</strong>
        </div>
      </template>
      <template slot="billing" slot-scope="record">
        <div v-if="record.sapResponses">
          <strong class="text-primary" v-if="record.sapResponses.is_billing">
            <b-icon icon="check-circle-fill"></b-icon>
            Facturado
          </strong>
          <strong class="text-danger" v-if="!record.sapResponses.is_billing">
            <b-icon icon="x-circle-fill"></b-icon>
            No Facturado
          </strong>
          <hr class="bg-primary" v-if="record.sapResponses.is_billing">
          <template v-if="record.sapResponses.is_billing">
            <span><strong>Factura: </strong></span>
            <span>{{ record.sapResponses.billing_code }}</span>
            <hr>
            <span><strong>Fecha: </strong></span>
            <span>{{ record.sapResponses.billing_dated_at }}</span>
          </template>
        </div>
        <strong v-else>Sin información de facturación</strong>
      </template>
      <!-- Custom Columns -->
      <template slot="action" slot-scope="data">
        <responsive-button variant="warning"
                           pill size="sm"
                           responsive="md"
                           text="Ir a descarga"
                           icon="arrow-right-circle-fill"
                           @ClickEvent="GoToDischarge(data.sap_programming_code, 'discharge_service_plannings')"
        />
        <div class="pt-2" v-if="data.sapResponses">
          <responsive-button variant="success"
                             pill size="sm"
                             responsive="md"
                             text="Cerrar Facturación"
                             icon="check-circle-fill"
                             @ClickEvent="OpenBillingModal({}, data)"
                             v-show="!data.sapResponses.is_billing"
          />
        </div>
      </template>
    </a-table>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'SapResponserTable',
  props: {
    value: {
      type: [Object],
      required: true,
    },
    buttonType: {
      type: [String],
      default: 'discharge',
    },
  },
  data() {
    return {
      // Own variables to table functionality
      searchText: '',
      searchInput: null,
      resourceType: 'manifests',
      // Own variables to table functionality
    }
  },
  watch: {
    searchInput(newValue) {
      this.$emit('searchInput', newValue)
    },
    value(newValue) {
      this.$emit('input', newValue)
    },
  },
  methods: {
    ...mapActions('servicePlannings', ['SET_SERVICEPLANNINGID', 'GOTO_SERVICEPLANNINGID']),
    HandleSearch(selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },
    HandleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    Calculate(action, data = {}) {
      this.$emit('Calculate', action, data)
    },
    RefreshResults(paginationEvent = {}) {
      this.$emit('Filter', paginationEvent)
    },
    OpenBillingModal(paginationEvent = {}, data = {}) {
      this.$emit('OpenBillingModal', paginationEvent, data)
    },
    GoToDischarge(serviceId, origin) {
      this.GOTO_SERVICEPLANNINGID({
        id: serviceId,
        name: origin,
      })
    },
  },
}
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0;
}
</style>
